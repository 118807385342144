<template>
   <div class="main-wrapper">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="margin-left: 20px">
            <el-breadcrumb-item>商品</el-breadcrumb-item>
            <el-breadcrumb-item>添加商品</el-breadcrumb-item>
            <el-breadcrumb-item>商品类别</el-breadcrumb-item>
        </el-breadcrumb>
         <div class="big-title">普通发品</div>
         <div class="main-content">
             <div class="history-wrapper"  v-clickoutside="hideSearchBox">
                   <span class="text">最近使用的类目</span>
                   <el-select v-model="historyClassValue"  placeholder="" style="width: 380px;margin-right:10px;"
                           @change="changeHistoryClass">                    
                    <el-option  v-for="(historyItem, historyIndex) in historyClassList" :key="`historyClassList_${historyIndex}`"
                               :label="historyItem.label" :value="historyItem.value"></el-option>
                    </el-select>
                    <div class="search-wrapper" style="position: relative;display: inline-block;">
                        <el-input placeholder="可输入类目关键字进行搜索" @keyup.enter.native="searchBtn" clearable
                            prefix-icon="el-icon-search" v-model="searchValue" style="width: 350px"></el-input>
                        <el-scrollbar class="search-list" v-if="showSearchBox">
                            <!-- <div class="text-overflow search-item"
                                @click="changeSearchClass(searchItem.label, searchItem.value)"
                                :title="searchItem.label"
                                v-for="(searchItem, searchIndex) in searchList"
                                :key="`searchList_${searchIndex}`">{{searchItem.label}}</div> -->
                                <div class="list" style="height: 100%;">
                                    <div class="item" v-for="(item, index) in searchList" :key="index"  >
                                        <div class="right-children-cate">
                                            <div class="text-overflow search-item" v-for="(children, cIndex) in item.children" :key="cIndex" @click="changeSearchClass(item,children)">
                                               {{item.category_name}} > {{children.category_name}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </el-scrollbar>
                    </div>
                  
             </div>
             <div class="category-wrapper">
                <div class="category-level">
                     <el-scrollbar class="level-item">
                         <div class="cate-item" @click="changeLevel(cateItem, 1)"
                             :ref="`level_1_${cateItem.id}`"
                             :class="class_id1 === cateItem.goods_class_id ? 'current' : ''"
                             v-for="(cateItem, cateIndex) in categoryList"
                             :key="`categoryList_${cateIndex}`">
                            <span class="text-overflow item-left">{{cateItem.category_name}}</span>
                            <i class="el-icon-caret-right" v-if="cateItem.hasOwnProperty('children') && cateItem.children.length > 0"></i>
                        </div>
                     </el-scrollbar>
                      <el-scrollbar class="level-item">
                        <div class="cate-item" @click="changeLevel(cateItem, 2)"
                             :ref="`level_2_${cateItem.id}`"
                             :class="class_id2 === cateItem.goods_class_id ? 'current' : ''"
                             v-for="(cateItem, cateIndex) in level2CategoryList"
                             :key="`level2CategoryList_${cateIndex}`">
                            <span class="text-overflow item-left">{{cateItem.category_name}}</span>
                            <i class="el-icon-caret-right" v-if="cateItem.hasOwnProperty('children') && cateItem.children.length > 0"></i>
                        </div>
                      </el-scrollbar>
                
                </div>
                <div class="category-choose">
                    <span class="text">您当前的选择是：</span>
                    <span class="value" v-if="class_name1">{{class_name1}}</span>
                    <span class="value" v-if="class_name2">{{class_name2}}</span>
                </div>
             </div>
              <el-button type="primary" @click="goTo" style="margin-left: 100px;">下一步，填写商品信息</el-button>
         </div>
    </div>
</template>

<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import {goodsList ,recentlyList,adminGoodRecentlyUsedCategory,showGoodListData} from '@/utils/apis'
export default {
    directives: { Clickoutside },
    data(){
        return{
            //使用类目的值
            historyClassValue:'',
            //使用类目的列表
            historyClassList:[],
            //搜索显示
            showSearchBox: false,
            //第一个列表
            categoryList: [],
            //第二个列表
            level2CategoryList:[],
            //列表两个名称
            class_name1: '',
            class_name2: '',
            //列表两个ID
            class_id1: null,
            class_id2: null,
            //搜索
            searchValue:'',   
            searchList: [],        
        }
    },
   created(){
       this.getHistoryClassList()
       this.getconList()
   },

    methods:{
        //返回上一步数据回显 
        upDate(){
            console.log('123')
            this.class_id1 = parseInt(this.$route.query.class_id1)
            this.class_id2 = parseInt(this.$route.query.class_id2)
            this.getLevel23CategoryList()
        },

        //编辑
        editEstablish(){
            let params = {
                id: this.$route.query.id,
            };
            showGoodListData(params).then((res) => {
                    this.class_id1 = res.data.goods_category[0]
                    this.class_id2 = res.data.goods_category[1]
                    let editData = res.data
            
                     localStorage.setItem("commodityeditData", JSON.stringify(editData));
                    this.getLevel23CategoryList()
                }).catch((err) => {
                    console.error('err', err)
                })
        },


        //请求列表数据
        getconList(){
            goodsList().then((res) => {  
                this.categoryList = res.data
                //判断是否编辑
                if (this.$route.query.id) {
                    this.editEstablish()
                }else if(this.$route.query.class_id1){
                     this.upDate()
                 }
           
            }).catch((err) => {
                console.error('err', err);
            });
        },
        // 获取最近使用的类目
        getHistoryClassList() {
            adminGoodRecentlyUsedCategory().then((res) => {
        
                if(res.code == 200){             
                    this.historyClassList = this.dealWithList(res)
                    this.getLevel23CategoryList()
                }
            })
        },
        // 点击空白地方关闭搜索列表
            hideSearchBox() {
                 this.showSearchBox = false
            },

        //整合最近使用的类目
        dealWithList(oddList){
            let arr = [
                {
                    label: oddList.data.category_name,
                    value: oddList.data.goods_class_id,
                }
            ]
            if (oddList.data.children) {
                arr[0].label = arr[0].label + ' > ' + oddList.data.children.category_name
                arr[0].value = arr[0].value + '|' + oddList.data.children.goods_class_id
            }
        
            return arr
        },

        //搜索
        dealWithList_two(oddList){
            let arr = []
            oddList.forEach(item => {
                let tmp = {
                    label: item.category_name,
                    value: item.goods_class_id,
                }
                if (item.children) {
                    for(let i=0;i<item.children.length;i++){
                        tmp.label = tmp.label + ' > ' + item.children[i].category_name
                        tmp.value = tmp.value + '|' + item.children[i].goods_class_id
                    }
                  
                }
                arr.push(tmp)
                
            })
            return arr
        },

        //搜索回车事件
        searchBtn(){
            if (!this.searchValue) {
                return this.$message.warning('请输入搜索关键词')
            }
             let params = {}
            if (this.searchValue) {
                params.category_name = this.searchValue
            }
            goodsList(params).then((res) => {
                if (res.data.length > 0) {
                    this.$message.success(res.msg)
                    //   this.searchList = this.dealWithList_two(res.data)
                       this.searchList = res.data
                    this.showSearchBox = true
                } else {
                    this.$message.warning('未搜索到该类目')
                }
            })
        },
        //搜索下拉框点击
        changeSearchClass(item,children){
            this.class_id1 = item.goods_class_id  
            this.class_id2 = children.goods_class_id
            this.class_name1 = item.category_name
            this.class_name2 = children.category_name
            this.getLevel23CategoryList()
       
            this.showSearchBox = false
      
        },
         // 筛选最近类目
        changeHistoryClass(val){
            let valTmp = val.split('|')
            let currentTmp = this.historyClassList.find(item => {
                    let itemTmp = item.value.split('|')
                    if (valTmp.length === 2) {
                        if (Number(valTmp[0]) === Number(itemTmp[0]) && Number(valTmp[1]) === Number(itemTmp[1])) {
                            return item
                        }
                    } 
            })
             this.dealWithShowCate(val, currentTmp.label)
        },
         // 整合处理最近使用类目选择，搜索选择 显示对应类目
        dealWithShowCate(val, label) {
            let arrTmp = val.split('|')
            let labelTmp = label.split(' > ')
            this.class_id1 = Number(arrTmp[0])
            this.class_name1 = labelTmp[0]
            if (arrTmp.length > 0) {
                this.class_id2 = null
                this.class_name2 = ''   
            }
            if (arrTmp.length > 1) {
                this.class_id2 = Number(arrTmp[1])
                this.class_name2 = labelTmp[1]
            }
             this.getLevel23CategoryList()
        },

        // 编辑的时候默认显示二级分类, 并跳到指定位置
            getLevel23CategoryList() {
              
                if (this.class_id1) {
                    let level1 = this.categoryList.find(item => {
                        if (this.class_id1 === item.goods_class_id) {
                            // 高亮元素跳到指定位置
                            this.$nextTick(() => {
                                this.$refs[`level_1_${item.id}`][0].scrollIntoView(false)
                            })
                        }
                        return this.class_id1 === item.goods_class_id
                    })
                    if (level1) {
                        this.level2CategoryList = level1.children || []
                    }
                }
                if (this.class_id2) {
                     this.level2CategoryList.find(item => {
                        if (this.class_id2 === item.goods_class_id) {
                            this.$nextTick(() => {
                                this.$refs[`level_2_${item.id}`][0].scrollIntoView(false)
                            })
                        }
                      
                        return this.class_id2 === item.goods_class_id
                    })
                    
                }         
            },


        //选择层数对应
        changeLevel(val,level){
             if (level === 1) {
                    this.class_id2 = null
                    this.class_name2 = ''         
                    this.class_id1 = val.goods_class_id
                    this.class_name1 = val.category_name
                    this.level2CategoryList = val.children || []                 
              }
              if (level === 2) {
                    this.class_id2 = val.goods_class_id
                    this.class_name2 = val.category_name                 
                }
             
        },

        // 跳转
        goTo() {
             if (this.categoryList.length > 0) {
                    if (!this.class_id1) {
                        return this.$message.warning('请选择一级分类')
                    }
                }
                if (this.level2CategoryList.length > 0) {
                    if (!this.class_id2) {
                        return this.$message.warning('请选择二级分类')
                    }
                }
            let params = {
              goods_class_id: this.class_id2,
            }
            recentlyList(params).then((res) => {                 

                localStorage.setItem("oneid", JSON.stringify(this.class_id2));
                  if (this.$route.query.id) {
                    this.$router.push({
                        path: '/liveStreaming/newComManagement/message',
                        query: { id: this.$route.query.id },
                    })
                  }else{
                    this.$router.push({
                        path: '/liveStreaming/newComManagement/message', query: { class_id1: this.class_id1,class_id2:this.class_id2 },
                    })
                  }
               
            })

        },
    }
}
</script>



<style lang="scss"  scoped>

   .main-wrapper {
        height: 100%;
        padding: 30px 30px 0;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .big-title {
            font-size: 24px;
            margin: 30px 0 0 20px;
            line-height: 1;
            font-weight: bold;
        }
        .main-content {
            padding: 30px 60px 100px;
             .history-wrapper {
                 
                & > .text {
                    font-size: 16px;
                    margin-right: 8px;
                }
                .search-list {
                        position: absolute;
                        left: 0;
                        top: 40px;
                        width: 350px;
                        height: 200px;
                        background: #fff;
                        border: 1px solid #DCDCDC;
                        box-sizing: border-box;
                        z-index: 9999;
                        .search-item {
                            line-height: 32px;
                            padding: 0 10px;
                            cursor: pointer;
                            transition: all .3s;
                            &:hover {
                                color: #2821fc;
                            }
                        }
                    }
            }
            .category-wrapper {
                 margin-top: 35px;
                margin-bottom: 20px;
                padding: 20px 30px;
                background: #F6F6F6;
                border: 1px solid #eee;
                .category-level {
                    margin: 20px 0;
                    display: flex;
                    .level-item {
                        width: 398px;
                        height: 418px;
                        background: #fff;
                        margin-left: 16px;
                        border: 1px solid #E5E5E5;
                        &:first-child {
                            margin-left: 0;
                        }
                        .cate-item {
                            padding: 0 25px 0 15px;
                            line-height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;
                            .item-left {
                                width: 1%;
                                flex: 1;
                            }
                            .iconfont {
                                color: #9CA7B6;
                            }
                            &.current {
                                background: #E7F6EF;
                            }
                        }
                    }
                }
                  .category-choose {
                    color: #666;
                    .text {
                        color: #999;
                    }
                    .value {
                        &:after {
                            content: ' > ';
                        }
                        &:last-child {
                            &:after {
                                content: none;
                            }
                        }
                    }
                }
            }
        }
    }
</style>